import React, { forwardRef } from 'react';
import { Card } from '@design-system/card';
import { ExtendableImage, ExtendableImageProps } from '@design-system/extendable-image';
import { FluidGrid } from '@design-system/fluid-grid';
import { HeadingBlock } from '@design-system/heading-block';
import { PageBlock } from '@design-system/page-block';
import { RetailerLink, RetailersBlock } from '@design-system/retailers-block';
import TMPLinkWrap from '@husky-x/gatsby-theme-husky/src/components/TMPLinkWrap';
import { headingBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/headingBlock';
import { HeadingBlock as HeadingBlockMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/headingBlock/models';
import { imageMapper } from '@husky-x/gatsby-theme-husky/src/mappers/image';
import { Image as ImageMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/image/models';
import { linkMapper } from '@husky-x/gatsby-theme-husky/src/mappers/link';
import { Link as LinkMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/link/models';
import { pageBlockMapper } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock';
import { PageBlock as PageBlockMapperProps } from '@husky-x/gatsby-theme-husky/src/mappers/pageBlock/models';
import { gtmService } from '@husky-x/gatsby-theme-husky/src/services/gtm';
import { GTM_LOCALSTORAGE_DATA } from '@husky-x/gatsby-theme-husky/src/utils/constants';
import { setLocalStorageData } from '@phx-husky/local-storage';

import { toCamelCase } from './util';

type RetailersProps = {
  uid: string;
  pageBlock: PageBlockMapperProps;
  items: {
    retailers: { image: ImageMapperProps; link: LinkMapperProps }[];
    retailersHeading: HeadingBlockMapperProps;
    heading: HeadingBlockMapperProps;
    image: ImageMapperProps;
  }[];
};

export const Retailers = forwardRef<HTMLDivElement, { modelData: unknown }>((props, ref) => {
  const { uid, pageBlock, items } = toCamelCase(props.modelData) as RetailersProps;

  const onClickRetailerName = (title: string, url: string) => {
    setLocalStorageData(GTM_LOCALSTORAGE_DATA.lastRetailerTitle, title);
    gtmService.emitRetailerClick(title, url);
  };

  return (
    <PageBlock {...pageBlockMapper(pageBlock)} className="retailers" componentUid={uid} ref={ref}>
      <FluidGrid variant="fluid" cardsPerRow={4} preferredCardSize={250}>
        {items.map(({ retailers, retailersHeading, heading, image }) => {
          const retailerLinks = retailers.map((item) => {
            const retailerImage = imageMapper(item.image) as ExtendableImageProps;
            const linkItem = { ...item.link, variant: 'retailer', size: 'large' };

            return {
              link: { component: TMPLinkWrap, props: linkMapper(linkItem) },
              children: <ExtendableImage {...retailerImage} />,
            } as RetailerLink;
          });

          return (
            <Card hasMedia>
              <Card.CardContent headingBlock={{ ...headingBlockMapper(heading) }}>
                <HeadingBlock {...headingBlockMapper(retailersHeading)} />
                <RetailersBlock
                  retailerLinks={retailerLinks}
                  onClickRetailerName={onClickRetailerName}
                />
              </Card.CardContent>
              <Card.CardMedia image={imageMapper(image)} />
            </Card>
          );
        })}
      </FluidGrid>
    </PageBlock>
  );
});
